/**
 * Return the coordinates in an SVG based on a event (e.g. mouse)
 * @param {SVGSVGElement} svg
 * @param {SyntheticMouseEvent} the mouse event
 * @returns {[Number, Number]}
 */
export function getPointInSvgFromEvent(svg, event) {
  const { touches, clientX, clientY } = event.nativeEvent;
  const x = touches ? touches[0].clientX : clientX;
  const y = touches ? touches[0].clientY : clientY;
  return getPointInSvg(svg, x, y);
}

/**
 * Return the coordinates in an SVG based on the DOM
 * @param {SVGSVGElement} svg
 * @param {Number} otherX
 * @param {Number} otherY
 * @returns {[Number, Number]}
 */
export function getPointInSvg(svg, otherX, otherY) {
  const pt = svg.createSVGPoint();

  pt.x = otherX;
  pt.y = otherY;
  const { x, y } = pt.matrixTransform(svg.getScreenCTM().inverse());
  return [x, y];
}

/**
 * Return a random color in HSLA
 * @returns {string}
 */
export function getRandomColorHSL() {
  const hue = getRandomHueHSL();
  return `hsl(${hue}, 80%, 50%)`;
}

/**
 * Return a random number from 0 to 255
 * @returns {number}
 */
export function getRandomHueHSL() {
  return Math.floor(Math.random() * Math.floor(360));
}
