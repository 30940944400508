import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { throttle } from "throttle-debounce";
import {
  addPath,
  addPoint,
  addPointToPath,
  removePath,
  selectCurrentFillStyle,
  selectIsDrawing,
  selectLastPointInPath,
  selectPaths,
  selectForeignPaths,
  selectPoints,
  setIsDrawing,
  setIsNotDrawing,
  setPaths,
  setForeignPaths,
  setPoints,
} from "./redux/features/cursorSlice";
import { getPointInSvgFromEvent } from "./util";
const isLocal = window.location.hostname == "localhost";
const socketUrl = ":3456";
const socket = io(socketUrl);

class Main extends React.Component {
  state = {
    path: "",
    isDrawing: false,
  };
  constructor(props) {
    super(props);
    this.mouseOrTouchUp = this.mouseOrTouchUp.bind(this);
    this.mouseOrTouchDown = this.mouseOrTouchDown.bind(this);
    this.drawMove = this.drawMove.bind(this);
    this.svgRef = React.createRef();
    this.nameInputRef = React.createRef();
  }
  componentDidMount() {
    socket.on("pathsUpdate", (r) => {
      this.props.setForeignPaths(r.paths);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.foreignPaths != prevProps.foreignPaths) {
      // console.log("received foreign paths", foreignPaths);
    }
  }

  /**
   * Draw while moving
   * @param event
   */
  drawMove(event) {
    if (this.props.isDrawing) {
      const svg = this.svgRef.current;
      const [x, y] = getPointInSvgFromEvent(svg, event);
      // this.drawPoint(x, y);
      this.drawPointInPath(x, y, "L");
    }
  }
  drawPointInPath(x, y, type = "M") {
    const { addPointToPath } = this.props;
    const pathChunk = `${type} ${x.toFixed(1)} ${y.toFixed(1)} `;
    addPointToPath(pathChunk);
    socket.emit("uploadPaths", this.props.paths);
  }
  addNewPath() {
    const { fillStyle } = this.props;
    this.props.addPath({ fillStyle });
  }

  mouseOrTouchDown(event) {
    const svg = this.svgRef.current;
    const [x, y] = getPointInSvgFromEvent(svg, event);
    this.props.setIsDrawing();
    this.addNewPath();
    this.drawPointInPath(x, y);
  }
  mouseOrTouchUp(event) {
    const svg = this.svgRef.current;
    const [x, y] = getPointInSvgFromEvent(svg, event);
    const { lastPointInPath = [] } = this.props;
    const [lastX, lastY, lastType] = lastPointInPath;
    this.props.setIsNotDrawing();
    if (lastType === "M") {
      this.drawPointInPath(x, y, "L");
    }
  }

  render() {
    return (
      <svg
        onMouseDown={this.mouseOrTouchDown}
        onTouchStart={this.mouseOrTouchDown}
        onMouseUp={this.mouseOrTouchUp}
        // onTouchEnd={this.mouseOrTouchUp}
        onMouseMove={this.drawMove}
        onTouchMove={this.drawMove}
        ref={this.svgRef}
        viewBox={`0 0 1000 1000`}
        style={{
          width: "100%",
          height: "100%",
          // enableBackground: "new 0 0 514.6 516.4;"
        }}
      >
        <g>
          {[this.props.paths, ...Object.values(this.props.foreignPaths)].map(
            (client, clientIndex) => {
              return client.map((path, index) => {
                const { fillStyle, d } = path;
                return (
                  <path
                    key={index}
                    style={fillStyle}
                    d={d}
                    strokeWidth={30}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                );
              });
            }
          )}
        </g>
      </svg>
    );
  }
}

const mapStateToProps = (state) => ({
  points: selectPoints(state),
  isDrawing: selectIsDrawing(state),
  fillStyle: selectCurrentFillStyle(state),
  paths: selectPaths(state),
  foreignPaths: selectForeignPaths(state),
  lastPointInPath: selectLastPointInPath(state),
});

const mapDispatchToProps = {
  addPoint,
  setPoints,
  setIsNotDrawing,
  setIsDrawing,
  setPaths,
  setForeignPaths,
  addPath,
  removePath,
  addPointToPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
