import React, { useEffect, useRef, useState } from "react";
import { connect, Provider } from "react-redux";
import io from "socket.io-client";
import { throttle } from "throttle-debounce";
import store from "./redux/store";
import Main from "./Main";

const App = (props) => (
  <Provider store={store}>
    <Main />
  </Provider>
);
export default App;
