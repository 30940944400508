import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { getRandomColorHSL } from "../../util";

const name = "cursor";
const randomColor = getRandomColorHSL();
const cursorSlice = createSlice({
  name: name,
  initialState: {
    isDrawing: false,
    currentTool: {},
    currentFillStyle: {
      fill: "transparent",
      stroke: randomColor,
      backgroundColor: randomColor,
    },
    currentPatternClass: "",
    toolStage: 0,
    points: [],
    paths: [],
    foreignPaths: {},
  },
  reducers: {
    setIsDrawing(state) {
      state.isDrawing = true;
    },
    setIsNotDrawing(state) {
      state.isDrawing = false;
    },
    setCurrentFillStyle(state, { payload }) {
      state.currentFillStyle = payload;
    },
    setCurrentTool(state, { payload }) {
      // const { stages, disabled } = state.tools[payload];
      state.currentTool = payload;
      state.toolStage = 0;
    },
    setNextToolStage(state, { payload }) {
      state.toolStage++;
    },
    setPaths(state, { payload }) {
      state.paths = payload;
    },
    setForeignPaths(state, { payload }) {
      state.foreignPaths = payload;
    },
    addPath(state, { payload }) {
      const { fillStyle } = payload;
      state.paths.push({
        fillStyle,
        d: "",
      });
    },
    removePath(state) {
      state.paths.pop();
    },
    addPointToPath(state, { payload }) {
      state.paths[state.paths.length - 1].d += payload;
    },
    setPoints(state, { payload }) {
      state.points = payload;
    },
    setCurrentPatternClass(state, { payload }) {
      state.currentPatternClass = payload;
    },
    addPoint(state, action) {
      const point = action.payload;
      state.points.push(point);

      if (point.stages) {
        if (state.toolStage < point.stages - 1) {
          state.toolStage++;
        } else {
          state.toolStage = 0;
        }
      }
    },
  },
});

export const selectIsDrawing = createSelector(
  (state) => state[name],
  (cursor) => cursor.isDrawing
);
export const selectCurrentFillStyle = createSelector(
  (state) => state[name],
  (cursor) => cursor.currentFillStyle
);
export const selectCurrentTool = createSelector(
  (state) => state[name],
  (cursor) => cursor.currentTool
);
export const selectToolStage = createSelector(
  (state) => state[name],
  (cursor) => cursor.toolStage
);
export const selectPoints = createSelector(
  (state) => state[name],
  (cursor) => cursor.points
);
export const selectPaths = createSelector(
  (state) => state[name],
  (cursor) => cursor.paths
);
export const selectForeignPaths = createSelector(
  (state) => state[name],
  (cursor) => cursor.foreignPaths
);

export const selectLastPointInPath = createSelector(selectPaths, (paths) => {
  const point = paths[paths.length - 1];
  if (point) {
    const numRegEx = "-?\\d+\\.\\d+";
    const lastCoordRegEx = new RegExp(`(\\w) (${numRegEx}) (${numRegEx})$`);
    const [_, type, x, y] = point.d.trim().match(lastCoordRegEx);
    return [x, y, type];
  }
});

export const selectCurrentPatternClass = createSelector(
  (state) => state[name],
  (cursor) => cursor.currentPatternClass
);

export const {
  setIsDrawing,
  setIsNotDrawing,
  setCurrentTool,
  setCurrentFillStyle,
  setNextToolStage,
  setPoints,
  addPoint,
  setPaths,
  setForeignPaths,
  addPath,
  removePath,
  addPointToPath,
  setCurrentPatternClass,
} = cursorSlice.actions;

export default cursorSlice.reducer;
