import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootReducer";
import cursor from "./features/cursorSlice";

const store = configureStore({
  reducer: {
    cursor: cursor,
  },
});

export default store;
